import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import HomePage from "../views/HomePage.vue";
import { setTitle, isPcWindow } from "@/util";
import { Loading } from "element-ui";
let loadingInstance = null;

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

var isPc = isPcWindow();

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    redirect: "/homepage/index",
    children: [
      {
        path: "/homepage/index",
        name: "index",
        component: () =>
          isPc
            ? import("@/views/homepage/index.vue")
            : import("@/views/homepage/mobile/index.vue"),
        meta: {
          title: "鼓楼e学校",
          keepAlive: false,
          isUseCache: false,
          noHeader: false,
          showTab: true,
        },
      },
      {
        path: "/homepage/login",
        name: "login",
        component: () => import("@/views/homepage/login.vue"),
        meta: {
          title: "鼓楼e学校",
          keepAlive: false,
          isUseCache: false,
        },
      },
      {
        path: "/homepage/my",
        name: "my",
        component: () => import("@/views/homepage/my.vue"),
        meta: {
          title: "数字化教学",
          keepAlive: false,
          isUseCache: false,
          noHeader: true,
        },
      },
      {
        path: "/homepage/register",
        name: "register",
        component: () => import("@/views/homepage/register.vue"),
        meta: {
          title: "鼓楼e学校",
          keepAlive: false,
          isUseCache: false,
          noHeader: true,
        },
      },
      {
        path: "/homepage/changeRole",
        component: () => import("@/views/homepage/changeRole.vue"),
        meta: {
          title: "鼓楼e学校",
          keepAlive: false,
          isUseCache: false,
          noHeader: true,
        },
      },
      {
        path: "/homepage/viewRecord",
        component: () => import("@/views/homepage/viewRecord.vue"),
        meta: {
          title: "鼓楼e学校",
          keepAlive: false,
          isUseCache: false,
          noHeader: true,
        },
      },
      {
        path: "/homepage/collects",
        component: () => import("@/views/homepage/collects.vue"),
        meta: {
          title: "鼓楼e学校",
          keepAlive: false,
          isUseCache: false,
          noHeader: true,
        },
      },
      {
        path: "/homepage/role",
        component: () => import("@/views/homepage/role.vue"),
        meta: {
          title: "鼓楼e学校",
          isHeader: true,
          meta: {
            title: "我的",
            keepAlive: false,
            isUseCache: false,
            noHeader: true,
          },
        },
      },
      {
        path: "/homepage/success",
        component: () => import("@/views/homepage/success.vue"),
        meta: {
          title: "鼓楼e学校",
          isHeader: true,
        },
      },
      {
        path: "/homepage/err",
        component: () => import("@/views/homepage/err.vue"),
        meta: {
          title: "鼓楼e学校",
          isHeader: true,
        },
      },
      {
        path: "/application",
        name: "application",
        component: () => import("@/views/application/index.vue"),
        redirect: "/application/front/index",
        children: [
          {
            path: "/application/front/index",
            name: "frontindex",
            component: () =>
              isPc
                ? import("@/views/application/front/index.vue")
                : import("@/views/application/front/mobile/index.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/application/front/detail/:appId",
            name: "frontdetail",
            component: () =>
              isPc
                ? import("@/views/application/front/detail.vue")
                : import("@/views/application/front/mobile/detail.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/application/admin/index",
            name: "adminindex",
            component: () =>
              isPc
                ? import("@/views/application/admin/index.vue")
                : import("@/views/application/admin/mobile/index.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/application/admin/audit",
            name: "adminaudit",
            component: () =>
              isPc
                ? import("@/views/application/admin/audit.vue")
                : import("@/views/application/admin/mobile/audit.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/application/admin/apply",
            name: "adminapply",
            component: () =>
              isPc
                ? import("@/views/application/admin/apply.vue")
                : import("@/views/application/admin/mobile/apply.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/application/admin/provincelist/:groupId/:groupName",
            name: "adminprovincelist",
            component: () =>
              isPc
                ? import("@/views/application/admin/provincelist.vue")
                : import("@/views/application/admin/mobile/provincelist.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/application/admin/schoollist/:groupId/:groupName",
            name: "adminschoollist",
            component: () =>
              isPc
                ? import("@/views/application/admin/schoollist.vue")
                : import("@/views/application/admin/mobile/schoollist.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/application/admin/add/:appId/:groupName/:groupId",
            name: "adminadd",
            component: () => import("@/views/application/admin/add.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/application/admin/select/:appId",
            name: "adminadd",
            component: () => import("@/views/application/admin/select.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/application/front/myapp",
            name: "applicationmyapp",
            component: () => import("@/views/application/front/myapp.vue"),
            meta: {
              title: "鼓楼e学校",
              isHeader: true,
            },
          },
        ],
      },
      {
        path: "/resource",
        name: "resource",
        component: () => import("@/views/resource/home.vue"),
        redirect: "/resource/index",
        children: [
          {
            path: "/resource/index",
            name: "resourceindex",
            component: () =>
              isPc
                ? import("@/views/resource/index.vue")
                : import("@/views/resource/mobile/index.vue"),
            meta: {
              title: "资源中心",
              keepAlive: true,
              isUseCache: false,
              showTab: true,
            },
          },
          {
            path: "/resource/tskc",
            name: "resourcetskc",
            component: () =>
              isPc
                ? import("@/views/resource/tskc.vue")
                : import("@/views/resource/mobile/tskc.vue"),
            meta: {
              title: "资源中心",
              keepAlive: true,
              isUseCache: false,
              showTab: true,
            },
          },
          {
            path: "/resource/kzkt",
            name: "resourcekzkt",
            component: () => import("@/views/resource/kzkt.vue"),
            meta: {
              title: "资源中心",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/resource/jlwx",
            name: "resourcejlwx",
            component: () => import("@/views/resource/jlwx.vue"),
            meta: {
              title: "资源中心",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/resource/collectzy",
            name: "resourcecollectzy",
            component: () =>
              isPc
                ? import("@/views/resource/recordzy.vue")
                : import("@/views/resource/mobile/recordzy.vue"),
            meta: {
              title: "资源中心",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/resource/collectts",
            name: "resourcecollectts",
            component: () =>
              isPc
                ? import("@/views/resource/recordts.vue")
                : import("@/views/resource/mobile/recordts.vue"),
            meta: {
              title: "资源中心",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/resource/likezy",
            name: "resourcelikezy",
            component: () =>
              isPc
                ? import("@/views/resource/recordzy.vue")
                : import("@/views/resource/mobile/recordzy.vue"),
            meta: {
              title: "资源中心",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/resource/likets",
            name: "resourcelikets",
            component: () =>
              isPc
                ? import("@/views/resource/recordts.vue")
                : import("@/views/resource/mobile/recordts.vue"),
            meta: {
              title: "资源中心",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/resource/detail/:resource_id",
            name: "resourcedetail",
            component: () =>
              isPc
                ? import("@/views/resource/detail.vue")
                : import("@/views/resource/mobile/detail.vue"),
            meta: {
              title: "资源中心",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/resource/clip/:resource_id/:type",
            name: "resourceclip",
            component: () => import("@/views/resource/clip.vue"),
            meta: {
              title: "资源中心",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/resource/upload",
            name: "resourceupload",
            component: () => import("@/views/resource/upload.vue"),
            meta: {
              title: "资源上传",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/resource/uploadspecial",
            name: "resourceuploadspecial",
            component: () => import("@/views/resource/uploadspecial.vue"),
            meta: {
              title: "资源上传",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/resource/collectzy",
            name: "resourcecollectzy",
            component: () => import("@/views/resource/recordzy.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: false,
              isUseCache: false,
            },
          },
        ],
      },
      {
        path: "/space",
        name: "space",
        component: () => import("@/views/teach/index.vue"),
        redirect: "/space/my",
        children: [
          {
            path: "/space/my",
            name: "teachmy",
            // component: () => import('@/views/teach/my.vue'),
            component: () =>
              isPc
                ? import("@/views/teach/my.vue")
                : import("@/views/teach/my_m.vue"),
            meta: {
              title: isPc ? "数字化教学" : "鼓楼e学校",
              keepAlive: false,
              isUseCache: false,
              showTab: true,
            },
          },
          {
            path: "/space/digital",
            name: "digital",
            component: () => import("@/views/teach/digital.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
              noHeader: true,
              showTab: true,
            },
          },
          {
            path: "/homepage/login",
            name: "login",
            component: () => import("@/views/homepage/login.vue"),
            path: "/space/bk/upload",
            name: "bkupload",
            component: () => import("@/views/resource/upload.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/xkw",
            name: "bkxkw",
            component: () => import("@/views/teach/bk/xkw.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/myspace",
            name: "bkmyspace",
            component: () =>
              isPc
                ? import("@/views/teach/bk/myspace.vue")
                : import("@/views/teach/bk/mobile/myspace.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/schoolspace",
            name: "bkschoolspace",
            component: () =>
              isPc
                ? import("@/views/teach/bk/schoolspace.vue")
                : import("@/views/teach/bk/mobile/schoolspace.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/mykit",
            name: "bkmykit",
            component: () => import("@/views/teach/bk/mykit.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/wps",
            name: "bkwps",
            component: () => import("@/views/teach/bk/wps.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/bk/index",
            name: "bkindex",
            component: () =>
              isPc
                ? import("@/views/teach/bk/index.vue")
                : import("@/views/teach/bk/mobile/index.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/myspace",
            name: "bkmyspace",
            component: () => import("@/views/teach/bk/myspace.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/edit",
            name: "bkedit",
            component: () => import("@/views/teach/bk/edit.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/schoolspace",
            name: "bkschoolspace",
            component: () => import("@/views/teach/bk/schoolspace.vue"),
            meta: {
              title: "数字化授课工具",
              keepAlive: true,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/mykit",
            name: "bkmykit",
            component: () => import("@/views/teach/bk/mykit.vue"),
          },
          {
            path: "/space/bk/basket",
            name: "bkbasket",
            component: () => import("@/views/teach/bk/basket.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/upload",
            name: "bkupload",
            component: () => import("@/views/resource/upload.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/wps",
            name: "bkwps",
            component: () => import("@/views/teach/bk/wps.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/kit/:id",
            name: "bkkit",
            component: () => import("@/views/teach/bk/kit.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/basket/:id",
            name: "bkbasket",
            component: () => import("@/views/teach/bk/basket.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/mobilebasket",
            name: "bkmobilebasket",
            component: () => import("@/views/teach/bk/mobile/basket.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/detail/:resource_id",
            name: "bkdetail",
            component: () =>
              isPc
                ? import("@/views/resource/detail.vue")
                : import("@/views/resource/mobile/detail.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/myspacedetail/:mySpaceId",
            name: "myspacedetail",
            component: () =>
              isPc
                ? import("@/views/teach/bk/detail.vue")
                : import("@/views/teach/bk/mobile/detail.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/mydetail/:resource_id",
            name: "bkmydetail",
            component: () =>
              isPc
                ? import("@/views/resource/detail.vue")
                : import("@/views/resource/mobile/detail.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/clip/:resource_id/:type",
            name: "bkclip",
            component: () => import("@/views/resource/clip.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
            },
          },
          {
            path: "/space/bk/add/:id/:type",
            name: "bkadd",
            component: () => import("../views/teach/bk/add.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/bk/success",
            name: "bksuccess",
            component: () => import("../views/teach/bk/success.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/bk/code",
            name: "bkcode",
            component: () => import("../views/teach/bk/code.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          // {
          //   path: "/space/bk/explain",
          //   name: "bkexplain",
          //   component: () => import("../views/teach/bk/explain.vue"),
          //   meta: {
          //     title: "数字化教学",
          //     keepAlive: false,
          //     isUseCache: false,
          //     noHeader: false,
          //   },
          // },
          {
            path: "/space/audit/index",
            name: "auditindex",
            component: () =>
              isPc
                ? import("../views/teach/audit/index.vue")
                : import("../views/teach/audit/mobile/index.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: true,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/audit/bkindex",
            name: "auditbkindex",
            component: () =>
              isPc
                ? import("../views/teach/audit/index.vue")
                : import("../views/teach/audit/mobile/index.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: true,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/audit/detail/:id/:type",
            name: "auditdetail",
            component: () =>
              isPc
                ? import("../views/teach/audit/detail.vue")
                : import("../views/teach/audit/mobile/detail.vue"),
            meta: {
              title: "数字化教学",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          // 作业
          {
            path: "/space/work/index",
            name: "workindex",
            component: () =>
              isPc
                ? import("../views/teach/work/index.vue")
                : import("../views/teach/work/mobile/index.vue"),
            meta: {
              title: "数字化教学_作业",
              keepAlive: true,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/work/upload/:id",
            name: "workupload",
            component: () => import("../views/teach/work/upload.vue"),
            meta: {
              title: "数字化教学_作业上传",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/work/quesbasket",
            name: "quesbasket",
            component: () => import("../views/teach/work/quesbasket.vue"),
            meta: {
              title: "数字化教学_题集篮",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/work/success",
            name: "quessuccess",
            component: () => import("../views/teach/work/success.vue"),
            meta: {
              title: "数字化教学_成功页",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/work/workmould",
            name: "workmould",
            component: () =>
              isPc
                ? import("../views/teach/work/workmould.vue")
                : import("../views/teach/work/mobile/workmould.vue"),
            meta: {
              title: "数字化教学_作业模板",
              keepAlive: true,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/work/exampaper/:id/:type",
            name: "exampaper",
            component: () => import("../views/teach/work/exampaper.vue"),
            meta: {
              title: "数字化教学_作业模板",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/work/exampapersuccess",
            name: "exampapersuccess",
            component: () => import("../views/teach/work/exampaperSuccess.vue"),
            meta: {
              title: "数字化教学_作业模板",
              keepAlive: true,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/work/preview",
            name: "space/work/preview",
            component: () => import("../views/teach/work/preview.vue"),
            meta: {
              title: "数字化教学_作业模板",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/work/ques",
            name: "space/work/ques",
            component: () =>
              isPc
                ? import("../views/teach/work/ques.vue")
                : import("../views/teach/work/mobile/ques.vue"),
            meta: {
              title: "数字化教学_我的题集",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/work/group",
            name: "space/work/group",
            component: () =>
              isPc
                ? import("../views/teach/work/group.vue")
                : import("../views/teach/work/mobile/group.vue"),
            meta: {
              title: "数字化教学_作业模板",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/correct/list",
            name: "space/correct/list",
            component: () =>
              isPc
                ? import("../views/teach/correct/list.vue")
                : import("../views/teach/correct/mobile/list.vue"),
            meta: {
              title: "数字化教学_批改作业",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/correct/stuwork/:distributeId",
            name: "space/correct/stuwork",
            component: () =>
              isPc
                ? import("../views/teach/correct/stuwork.vue")
                : import("../views/teach/correct/mobile/stuwork.vue"),
            meta: {
              title: "数字化教学_批改作业",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/correct/statistic/:id",
            name: "space/correct/statistic",
            component: () =>
              isPc
                ? import("../views/teach/correct/statistic.vue")
                : import("../views/teach/correct/mobile/statistic.vue"),
            meta: {
              title: "数字化教学_作业数据",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/correct/correct/:homeworkId/:studentId",
            name: "space/correct/correct",
            component: () =>
              isPc
                ? import("../views/teach/correct/correct.vue")
                : import("../views/teach/correct/mobile/correct.vue"),
            meta: {
              title: "数字化教学_作业数据",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/correct/correctall/:homeworkId",
            name: "space/correct/correctall",
            component: () => import("../views/teach/correct/correctall.vue"),
            meta: {
              title: "数字化教学_作业数据",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/write/list",
            name: "space/write/list",
            component: () =>
              isPc
                ? import("../views/teach/write/list.vue")
                : import("../views/teach/write/mobile/list.vue"),
            meta: {
              title: "数字化教学_去作业",
              keepAlive: true,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/write/preview",
            name: "space/write/preview",
            component: () => import("../views/teach/write/preview.vue"),
            meta: {
              title: "数字化教学_去作业",
              keepAlive: true,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/my/preview",
            name: "space/my/preview",
            component: () => import("../views/teach/preview.vue"),
            meta: {
              title: "数字化教学_去作业",
              keepAlive: true,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/space/analysis/index",
            name: "space/analysis/index",
            component: () => import("../views/teach/analysis/index.vue"),
            meta: {
              title: "数字化授课工具_去作业",
              keepAlive: true,
              isUseCache: false,
              noHeader: false,
            },
          },
        ],
      },
      {
        path: "/set",
        name: "set",
        component: () => import("@/views/set/index.vue"),
        children: [
          {
            path: "/set/group",
            name: "setgroup",
            component: () => import("../views/set/group.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/set/admin",
            name: "setadmin",
            component: () => import("../views/set/admin.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/set/jtx",
            name: "setjtx",
            component: () => import("../views/set/jtx.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
          {
            path: "/set/tsmaintain",
            name: "settsmaintain",
            component: () => import("../views/set/tsmaintain.vue"),
            meta: {
              title: "鼓楼e学校",
              keepAlive: false,
              isUseCache: false,
              noHeader: false,
            },
          },
        ],
      },
      {
        path: "/space/analysis/index",
        name: "space/analysis/index",
        component: () =>
          isPc
            ? import("../views/teach/analysis/index.vue")
            : import("../views/teach/analysis/mobile/index.vue"),
        meta: {
          title: "数字化教学_去作业",
          keepAlive: true,
          isUseCache: false,
          noHeader: false,
        },
      },
      {
        path: "/space/analysis/class/:clazzId",
        name: "space/analysis/class",
        component: () =>
          isPc
            ? import("../views/teach/analysis/classData.vue")
            : import("../views/teach/analysis/mobile/classData.vue"),
        meta: {
          title: "数字化教学_去作业",
          keepAlive: true,
          isUseCache: false,
          noHeader: false,
        },
      },
      {
        path: "/space/analysis/stu/:studentId/:clazzId",
        name: "space/analysis/stu",
        component: () =>
          isPc
            ? import("../views/teach/analysis/stuData.vue")
            : import("../views/teach/analysis/mobile/stuData.vue"),
        meta: {
          title: "数字化教学_去作业",
          keepAlive: true,
          isUseCache: false,
          noHeader: false,
        },
      },
    ],
  },
  {
    path: "/screen/index",
    name: "screen/index",
    component: () => import("../views/screen/index.vue"),
    children: [
      {
        path: "/screen/index",
        name: "/screenIndex",
        component: () => import("../views/screen/cockpit.vue"),
        meta: {
          title: "数据与驾驶舱",
          keepAlive: false,
          isUseCache: false,
          noHeader: false,
        },
      },
    ],
  },
  {
    path: "/statistics",
    name: "statistics",
    component: () => import("../views/statistics.vue"),
    meta: {
      title: "鼓楼e学校",
      keepAlive: false,
      isUseCache: false,
      noHeader: false,
    },
  },
  {
    path: "/tips",
    name: "tips",
    component: () => import("../views/tips.vue"),
    meta: {
      title: "提示",
      keepAlive: false,
      isUseCache: false,
      noHeader: false,
    },
  },
  {
    path: "/space/bk/fullscreen/:id",
    name: "bkfullscreen",
    component: () => import("../views/teach/bk/fullscreen.vue"),
    meta: {
      title: "数字化教学",
      keepAlive: false,
      isUseCache: false,
      noHeader: false,
    },
  },
  {
    path: "/space/bk/spacescreen/:id/:type",
    name: "bkspacescreen",
    component: () => import("../views/teach/bk/spacescreen.vue"),
    meta: {
      title: "数字化教学",
      keepAlive: false,
      isUseCache: false,
      noHeader: false,
    },
  },
  {
    path: "/space/bk/code",
    name: "bkcode",
    component: () => import("../views/teach/bk/code.vue"),
    meta: {
      title: "数字化教学",
      keepAlive: false,
      isUseCache: false,
      noHeader: false,
    },
  },
  {
    path: "/space/bk/teaupload",
    name: "teaupload",
    component: () => import("../views/teach/bk/upload.vue"),
    meta: {
      title: "数字化教学",
      keepAlive: false,
      isUseCache: false,
      noHeader: false,
    },
  },
  {
    path: "/space/bk/spacescreen/:id/:type",
    name: "bkspacescreen",
    component: () => import("../views/teach/bk/spacescreen.vue"),
    meta: {
      title: "数字化教学",
      keepAlive: false,
      isUseCache: false,
      noHeader: false,
    },
  },
  {
    path: "/space/studentvideo",
    name: "space/studentvideo",
    component: () => import("../views/studentvideo.vue"),
    meta: {
      title: "预览视频",
      keepAlive: false,
      isUseCache: false,
      noHeader: true,
    },
  },
  {
    path: "/space/detail/:resource_id",
    name: "space/detail",
    component: () => import("../views/space/detail.vue"),
    meta: {
      title: "鼓楼e学校",
      keepAlive: false,
      isUseCache: false,
      noHeader: true,
    },
  },
  {
    path: "/forget",
    name: "forget",
    component: () => import("../views/teach/forget.vue"),
    meta: {
      title: "鼓楼e学校_忘记密码",
      keepAlive: false,
      isUseCache: false,
      noHeader: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/teach/login.vue"),
    meta: {
      title: "鼓楼e学校_登录",
      keepAlive: false,
      isUseCache: false,
      noHeader: true,
    },
  },
  {
    path: "/appDataScreen",
    name: "AppDataScreen",
    component: () => import("../views/appDataScreen/index.vue"),
    meta: {
      title: "应用数据大屏",
      keepAlive: false,
      isUseCache: false,
      noHeader: false,
    },
  },
  {
    path: "/space/bk/explain",
    name: "bkexplain",
    component: () => import("../views/teach/bk/explain.vue"),
    meta: {
      title: "数字化教学",
      keepAlive: false,
      isUseCache: false,
      noHeader: false,
    },
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path === from.path) {
      // 如果路由路径相同，则不需要保存位置信息，返回空对象
      return {};
    } else if (savedPosition) {
      // 如果有保存的位置信息，则返回该位置信息
      return savedPosition;
    } else {
      // 否则返回顶部位置信息
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach((to, form, next) => {
  loadingInstance = Loading.service({ fullscreen: true });
  to.meta.title && setTitle(to.meta.title);
  next();
});
router.afterEach(() => {
  loadingInstance.close();
});

export default router;
